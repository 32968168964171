import { useEffect, useState } from 'react';
// import Cockpit from './components/navigator/Cockpit/Cockpit';
import axios from 'axios';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// import Header from './components/navigator/Header/Header';
import Header from './Header/Header';
import Cockpit from './Cockpit/Cockpit';

function Navigator() {
  const [IVRList, setIVRList] = useState(null);

  useEffect(() => {
    const getIVRList = async () => {
      try {
        const IVRListResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/cockpit/getIVRDetails`
        );
        setIVRList(IVRListResponse.data);
      } catch (err) {
        console.log('IVR Config Fetch Error : ' + err);
      }
    };
    getIVRList();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Header />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '1rem',
          }}
        >
          {IVRList &&
            IVRList.map((res) => {
              return <Cockpit key={res.id} data={res} />;
            })}
          {/* {data &&
          data.map((res) => {
            return <Cockpit key={res.id} data={res} />;
          })} */}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default Navigator;
