import { useEffect, useState } from 'react';
import axios from 'axios';

import EditIcon from '@mui/icons-material/Edit';
import SpeedIcon from '@mui/icons-material/Speed';
import TitleIcon from '@mui/icons-material/Title';
import ContactsIcon from '@mui/icons-material/Contacts';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Stack,
  Grid,
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import TextEditModal from './TextEditModal';
import HolidayModal from './HolidayModal';
import dayjs from 'dayjs';

export const iconWrapperStyle = {
  borderRadius: '2px',
  border: '1px solid',
  // borderColor: 'grey.300',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  padding: '0.25rem',
  borderColor: 'rgba(48, 77, 112, 0.1)',
};

export const gridItemStyle = {
  flex: '22%',
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '10px',
};

export const boxItemStyle = {
  padding: '1rem',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '0.5rem',
};

export const titleStyle = {
  fontSize: '1rem',
  // color: 'black',
  alignSelf: 'center',
  color: '#0a0f16',
  //   padding: '0.5rem',
};

export const iconStyle = {
  color: '#304d70',
  width: '20px',
  height: '20px',
};

export const editBtnWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const editBtnStyle = {
  // color: 'black',
  color: '#304d70',
  fontSize: '14px',
  paddingRight: '4px',
};

export const stackBoxWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
  color: 'black',
  width: '100%',
};

export const selectStyle = {
  width: '100%',
  backgroundColor: 'grey.100',
  // paddingRight: '0.5rem',
  // paddingLeft: '0.5rem',
};

export const textFieldStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.9rem',
  maxWidth: '13rem',
  color: '#0a0f16',
};

const modalDefaultState = {
  open: false,
  IVRName: '',
  title: '',
  defaultValue: '',
  name: '',
};

const holdiayModalDefaultState = {
  open: false,
  IVRName: '',
  // dates: [{ date: dayjs(new Date()).format('DD/MM/YYYY') }],
  dates: [],
};

const Cockpit = ({ data }) => {
  const [resData, setResData] = useState(data);
  const [modalState, setModalState] = useState({ ...modalDefaultState });
  const [holidayModalState, setHolidayModalState] = useState({
    ...holdiayModalDefaultState,
  });
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (resData.Holiday) {
      setHolidayModalState((prev) => {
        const datesArr = resData.Holiday ? resData.Holiday.split(',') : [];
        const dates = {
          ...prev,
          IVRName: resData.IVRName,
          dates: datesArr,
        };
        return dates;
      });
    } else {
      setHolidayModalState((prev) => {
        return { ...prev, dates: [] };
      });
    }
  }, [resData.Holiday]);

  useEffect(() => {
    if (isUpdate) {
      const updateIVRConfig = async (newIVRData) => {
        try {
          const updateIVRRespone = await axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/cockpit/updateIVRDetails`,
            newIVRData,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
          console.log('IVR Configuration Update Success : ' + updateIVRRespone);
        } catch (err) {
          console.log('IVR Configuration Update Failed : ' + err);
        }
      };
      updateIVRConfig(resData);
      setIsUpdate(false);
    }
  }, [isUpdate]);

  const handleChange = (e) => {
    onSave(e.target.name, e.target.value);
  };

  const onSave = (name, value) => {
    setResData((prev) => {
      return { ...prev, [name]: value };
    });
    setIsUpdate(true);
  };

  const handleModalState = (name, title, defaultValue) => {
    setModalState({
      open: true,
      IVRName: resData.IVRName,
      name,
      title,
      defaultValue,
    });
  };

  const handleDateSave = () => {
    let dateStr = '';
    holidayModalState.dates.forEach((item, index) => {
      dateStr += item;
      if (index < holidayModalState.dates.length - 1) dateStr += ',';
      return dateStr;
    });

    setResData((prev) => {
      return { ...prev, Holiday: dateStr };
    });
    setIsUpdate(true);
  };

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          // padding: '1rem'
          // borderBottom: '1px solid',
          // borderColor: '#243B55',
        }}
        elevate={2}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            padding: '2rem',
            justifyContent: 'space-between',
            borderRight: '1px solid',
            borderColor: 'grey.300',
            // marginRight: '1rem',
            minWidth: '200px',
            // background: '#8B97A5',
            background: '#243B55',
            borderBottom: '1px solid',
            borderColor: '#A9ADA5',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              // color: '#232a50',
              // color: '#1d2e43',
              color: '#A9ADA5',
              fontSize: '20px',
            }}
          >
            {/* {resData.IVRName} */}
            {'IVR_Global_Config'}
          </Typography>
          <IconButton>
            <LaunchIcon
              sx={{
                // color: '#304d70',
                color: '#A9ADA5',
              }}
            />
          </IconButton>
        </Stack>
        <Box
          sx={{
            flex: '5',
            padding: '1rem',
            borderBottom: '1px solid',
            borderColor: '#243B55',
          }}
        >
          <Box
            // container
            // flex={4}
            flex={5}
            columnGap={2}
            rowGap={2}
            // marginLeft={2}
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'welcome-message'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <TitleIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Welcome Messages'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'WelcomeMessage',
                      'Welcome Messages',
                      resData.WelcomeMessage
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.WelcomeMessage}</Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'opening-hours'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <InfoIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Opening Hours'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'OpeningHours',
                      'Opening Hours',
                      resData.OpeningHours
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <LaunchIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.OpeningHours}</Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'closed-message'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <WatchLaterIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Closed Messasge'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'ClosedMessage',
                      'Closed Message',
                      resData.ClosedMessage
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.ClosedMessage}</Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'emergency-switch'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <TitleIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Emergency Switch'}</Box>
                </Stack>
                <Select
                  name='EmergencySwitch'
                  value={resData.EmergencySwitch}
                  variant='standard'
                  sx={selectStyle}
                  onChange={handleChange}
                >
                  <MenuItem key={`emergency-switch-true`} value={'true'}>
                    true
                  </MenuItem>
                  <MenuItem key={`emergency-switch-false`} value={'false'}>
                    false
                  </MenuItem>
                </Select>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'forwardnumber'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <ContactsIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Forward Number'}</Box>
                </Stack>
                <Box sx={selectStyle}>
                  <TextField
                    sx={{
                      width: '100%',
                    }}
                    variant='standard'
                    onBlur={(e) => {
                      if (
                        resData.ForwardNumber !== e.target.value &&
                        e.target.length !== ''
                      )
                        onSave('ForwardNumber', e.target.value);
                    }}
                    defaultValue={resData.ForwardNumber}
                  />
                </Box>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'callpriority'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <ContactsIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Call Priority'}</Box>
                </Stack>
                <Select
                  name='CallPriority'
                  value={resData.CallPriority}
                  variant='standard'
                  onChange={handleChange}
                  sx={selectStyle}
                >
                  {[...Array(10)].map((value, index) => (
                    <MenuItem key={`CallPriority-${index}`} value={index}>
                      {index}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'holdmessage'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <TitleIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Hold Message'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'HoldMessage',
                      'Hold Message',
                      resData.HoldMessage
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.HoldMessage}</Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'recording'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <SpeedIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Recording'}</Box>
                </Stack>
                <Select
                  name='Recording'
                  value={resData.Recording}
                  variant='standard'
                  sx={selectStyle}
                  onChange={handleChange}
                >
                  <MenuItem key={`recording-true`} value={'true'}>
                    true
                  </MenuItem>
                  <MenuItem key={`recording-false`} value={'false'}>
                    false
                  </MenuItem>
                </Select>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'queueposition'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <TitleIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Position in Queue'}</Box>
                </Stack>
                <Select
                  name='QueuePosition'
                  value={resData.QueuePosition}
                  variant='standard'
                  sx={selectStyle}
                  onChange={handleChange}
                >
                  <MenuItem key={`queueposition-true`} value={'true'}>
                    true
                  </MenuItem>
                  <MenuItem key={`queueposition-false`} value={'false'}>
                    false
                  </MenuItem>
                </Select>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'callback'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <SpeedIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Callback'}</Box>
                </Stack>
                <Select
                  name='Callback'
                  value={resData.Callback}
                  variant='standard'
                  sx={selectStyle}
                  onChange={handleChange}
                >
                  <MenuItem key={`callback-true`} value={'true'}>
                    true
                  </MenuItem>
                  <MenuItem key={`callback-false`} value={'false'}>
                    false
                  </MenuItem>
                </Select>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'holiday-message'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <WatchLaterIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Holiday Management'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() => {
                    setHolidayModalState((prev) => ({
                      ...prev,
                      IVRName: resData.IVRName,
                      open: true,
                    }));
                  }}
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={{ ...textFieldStyle, width: '100%' }}>
                    {resData.Holiday ?? dayjs().format('DDMMYYYY')}
                  </Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'queue-message'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <WatchLaterIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Queue Name'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'QueueName',
                      'Queue Name',
                      resData.QueueName
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>
                    {resData.QueueName ?? 'Standemo1'}
                  </Box>
                </Button>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Paper>
      <TextEditModal
        onSave={onSave}
        state={modalState}
        onClose={() => setModalState(modalDefaultState)}
      />
      {holidayModalState.open && (
        <HolidayModal
          IVRName={holidayModalState.IVRName}
          open={holidayModalState.open}
          onSave={handleDateSave}
          dates={holidayModalState.dates}
          onClose={() => setHolidayModalState(holdiayModalDefaultState)}
          onDateChange={(index, value) => {
            setHolidayModalState((prev) => {
              const modifiedDate = [...prev.dates];
              modifiedDate[index] = dayjs(value).format('DD/MM/YYYY');
              return { ...prev, dates: modifiedDate };
            });
          }}
          onAddDate={() => {
            setHolidayModalState((prev) => {
              return {
                ...prev,
                dates: [...prev.dates, dayjs().format('DD/MM/YYYY')],
              };
            });
          }}
          onDeleteDate={(index) => {
            setHolidayModalState((prev) => {
              return {
                ...prev,
                dates: prev.dates.filter(
                  (value, currIndex) => index !== currIndex
                ),
              };
            });
          }}
        />
      )}
    </>
  );
};

export default Cockpit;
