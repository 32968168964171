import { AppBar, Box, Typography } from '@mui/material';

// import logo from '../../assets/images/logo.png';
import logo from '../../../assets/images/logo.png';

const Header = () => {
  return (
    <AppBar
      sx={{
        position: 'sticky',
        bgcolor: '#161A30',
        top: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          padding: '1rem',
        }}
      >
        <Box color={'white'}>
          <img src={logo} width={32} height={32} />
        </Box>
        <Typography variant='h5'>CC Navigator</Typography>
      </Box>
    </AppBar>
  );
};

export default Header;
