import { AppBar, Box, Toolbar, Typography } from '@mui/material';

import logo from '../../../assets/images/logo1.png';

const Header = () => {
  return (
    <AppBar
      sx={{
        position: 'sticky',
        bgcolor: '#161A30',
        background: 'linear-gradient(to right,#ffffff, #243B55)',
        top: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          padding: '12px',
          borderBottom: 'solid 3px #0078ff',
        }}
      >
        <img src={logo} width={'auto'} height={39} />
      </Box>
    </AppBar>
  );
};

export default Header;
