import { useEffect, useState } from 'react';
import axios from 'axios';

import EditIcon from '@mui/icons-material/Edit';
import SpeedIcon from '@mui/icons-material/Speed';
import TitleIcon from '@mui/icons-material/Title';
import ContactsIcon from '@mui/icons-material/Contacts';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Stack,
  Grid,
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import TextEditModal from './TextEditModal';
import HolidayModal from './HolidayModal';
import dayjs from 'dayjs';

const iconWrapperStyle = {
  borderRadius: '2px',
  border: '1px solid',
  borderColor: 'grey.300',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  padding: '0.25rem',
};

const gridItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '10px',
};

const boxItemStyle = {
  padding: '1rem',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '0.5rem',
};

const titleStyle = {
  fontSize: '1rem',
  color: 'black',
  alignSelf: 'center',
  //   padding: '0.5rem',
};

const iconStyle = {
  color: 'black',
  width: '20px',
  height: '20px',
};

const editBtnWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const editBtnStyle = {
  color: 'black',
  fontSize: '14px',
  paddingRight: '4px',
};

const stackBoxWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
  color: 'black',
  width: '100%',
};

const selectStyle = {
  width: '100%',
  backgroundColor: 'grey.100',
  // paddingRight: '0.5rem',
  // paddingLeft: '0.5rem',
};

const textFieldStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.9rem',
};

const modalDefaultState = {
  open: false,
  IVRName: '',
  title: '',
  defaultValue: '',
  name: '',
};

const holdiayModalDefaultState = {
  open: false,
  IVRName: '',
  dates: [{ date: new Date().toDateString() }],
};

const Cockpit = ({ data }) => {
  const [resData, setResData] = useState(data);
  const [modalState, setModalState] = useState({ ...modalDefaultState });
  const [holidayModalState, setHolidayModalState] = useState({
    ...holdiayModalDefaultState,
  });
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (isUpdate) {
      const updateIVRConfig = async (newIVRData) => {
        try {
          const updateIVRRespone = await axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/cockpit/updateIVRDetails`,
            newIVRData,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
          console.log('IVR Configuration Update Success : ' + updateIVRRespone);
        } catch (err) {
          console.log('IVR Configuration Update Failed : ' + err);
        }
      };
      updateIVRConfig(resData);
      setIsUpdate(false);
    }
  }, [isUpdate]);

  const handleChange = (e) => {
    onSave(e.target.name, e.target.value);
  };

  const onSave = (name, value) => {
    setResData((prev) => {
      return { ...prev, [name]: value };
    });
    setIsUpdate(true);
  };

  const handleModalState = (name, title, defaultValue) => {
    setModalState({
      open: true,
      IVRName: resData.IVRName,
      name,
      title,
      defaultValue,
    });
  };

  const handleDateSave = () => {
    console.log('Holiday Dates:', holidayModalState.dates);
    const modifiedDateStr = holidayModalState.dates.map((item) => item.date);
    console.log('Modified Dates:', JSON.stringify(modifiedDateStr));
    setResData((prev) => {
      return { ...prev, holiday: JSON.stringify(modifiedDateStr) };
    });
    setIsUpdate(true);
  };

  return (
    <>
      <Paper sx={{ display: 'flex', padding: '1rem' }} elevate={2}>
        <Stack
          sx={{
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            padding: '2rem',
            justifyContent: 'space-between',
            borderRight: '1px solid',
            borderColor: 'grey.300',
            marginRight: '1rem',
            minWidth: '200px',
          }}
        >
          <Typography variant='h6' sx={{ color: '#232a50', fontSize: '20px' }}>
            {resData.IVRName}
          </Typography>
          <IconButton>
            <LaunchIcon />
          </IconButton>
        </Stack>
        <Grid container flex={4} columnGap={2} rowGap={2} marginLeft={2}>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'welcome-message'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <TitleIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Welcome Messages'}</Box>
              </Stack>
              <Button
                sx={stackBoxWrapperStyle}
                onClick={() =>
                  handleModalState(
                    'WelcomeMessage',
                    'Welcome Messages',
                    resData.WelcomeMessage
                  )
                }
              >
                <Box sx={editBtnWrapperStyle}>
                  <EditIcon sx={editBtnStyle} />
                </Box>
                <Box sx={textFieldStyle}>{resData.WelcomeMessage}</Box>
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'opening-hours'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <InfoIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Opening Hours'}</Box>
              </Stack>
              <Button
                sx={stackBoxWrapperStyle}
                onClick={() =>
                  handleModalState(
                    'OpeningHours',
                    'Opening Hours',
                    resData.OpeningHours
                  )
                }
              >
                <Box sx={editBtnWrapperStyle}>
                  <LaunchIcon sx={editBtnStyle} />
                </Box>
                <Box sx={textFieldStyle}>{resData.OpeningHours}</Box>
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'closed-message'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <WatchLaterIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Closed Messasge'}</Box>
              </Stack>
              <Button
                sx={stackBoxWrapperStyle}
                onClick={() =>
                  handleModalState(
                    'ClosedMessage',
                    'Closed Message',
                    resData.ClosedMessage
                  )
                }
              >
                <Box sx={editBtnWrapperStyle}>
                  <EditIcon sx={editBtnStyle} />
                </Box>
                <Box sx={textFieldStyle}>{resData.ClosedMessage}</Box>
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'emergency-switch'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <TitleIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Emergency Switch'}</Box>
              </Stack>
              <Select
                name='EmergencySwitch'
                value={resData.EmergencySwitch}
                variant='standard'
                sx={selectStyle}
                onChange={handleChange}
              >
                <MenuItem key={`emergency-switch-true`} value={'true'}>
                  true
                </MenuItem>
                <MenuItem key={`emergency-switch-false`} value={'false'}>
                  false
                </MenuItem>
              </Select>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'forwardnumber'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <ContactsIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Forward Number'}</Box>
              </Stack>
              <Box sx={selectStyle}>
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  variant='standard'
                  onBlur={(e) => {
                    if (
                      resData.ForwardNumber !== e.target.value &&
                      e.target.length !== ''
                    )
                      onSave('ForwardNumber', e.target.value);
                  }}
                  defaultValue={resData.ForwardNumber}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'callpriority'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <ContactsIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Call Priority'}</Box>
              </Stack>
              <Select
                name='CallPriority'
                value={resData.CallPriority}
                variant='standard'
                onChange={handleChange}
                sx={selectStyle}
              >
                {[...Array(10)].map((value, index) => (
                  <MenuItem key={`CallPriority-${index}`} value={index}>
                    {index}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'holdmessage'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <TitleIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Please Hold Msg'}</Box>
              </Stack>
              <Button
                sx={stackBoxWrapperStyle}
                onClick={() =>
                  handleModalState(
                    'HoldMessage',
                    'Hold Message',
                    resData.HoldMessage
                  )
                }
              >
                <Box sx={editBtnWrapperStyle}>
                  <EditIcon sx={editBtnStyle} />
                </Box>
                <Box sx={textFieldStyle}>{resData.HoldMessage}</Box>
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'recording'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <SpeedIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Recording'}</Box>
              </Stack>
              <Select
                name='Recording'
                value={resData.Recording}
                variant='standard'
                sx={selectStyle}
                onChange={handleChange}
              >
                <MenuItem key={`recording-true`} value={'true'}>
                  true
                </MenuItem>
                <MenuItem key={`recording-false`} value={'false'}>
                  false
                </MenuItem>
              </Select>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'queueposition'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <TitleIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Position in Queue'}</Box>
              </Stack>
              <Select
                name='QueuePosition'
                value={resData.QueuePosition}
                variant='standard'
                sx={selectStyle}
                onChange={handleChange}
              >
                <MenuItem key={`queueposition-true`} value={'true'}>
                  true
                </MenuItem>
                <MenuItem key={`queueposition-false`} value={'false'}>
                  false
                </MenuItem>
              </Select>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'callback'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <SpeedIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Callback'}</Box>
              </Stack>
              <Select
                name='Callback'
                value={resData.Callback}
                variant='standard'
                sx={selectStyle}
                onChange={handleChange}
              >
                <MenuItem key={`callback-true`} value={'true'}>
                  true
                </MenuItem>
                <MenuItem key={`callback-false`} value={'false'}>
                  false
                </MenuItem>
              </Select>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'holiday-message'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <WatchLaterIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Holiday Management'}</Box>
              </Stack>
              <Button
                sx={stackBoxWrapperStyle}
                onClick={() => {
                  setHolidayModalState((prev) => ({
                    ...prev,
                    IVRName: resData.IVRName,
                    open: true,
                  }));
                }}
              >
                <Box sx={editBtnWrapperStyle}>
                  <EditIcon sx={editBtnStyle} />
                </Box>
                <Box sx={textFieldStyle}>
                  {resData.holidayManagement ?? dayjs().format('DD/MM/YYYY')}
                </Box>
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={6}
            xs={12}
            sx={gridItemStyle}
            key={'queue-message'}
          >
            <Paper elevation={2} sx={boxItemStyle}>
              <Stack sx={stackBoxWrapperStyle}>
                <Box sx={iconWrapperStyle}>
                  <WatchLaterIcon sx={iconStyle} />
                </Box>
                <Box sx={titleStyle}>{'Queue Name'}</Box>
              </Stack>
              <Button
                sx={stackBoxWrapperStyle}
                onClick={() =>
                  handleModalState('QueueName', 'Queue Name', resData.QueueName)
                }
              >
                <Box sx={editBtnWrapperStyle}>
                  <EditIcon sx={editBtnStyle} />
                </Box>
                <Box sx={textFieldStyle}>
                  {resData.QueueName ?? 'Standemo1'}
                </Box>
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <TextEditModal
        onSave={onSave}
        state={modalState}
        onClose={() => setModalState(modalDefaultState)}
      />
      <HolidayModal
        IVRName={holidayModalState.IVRName}
        open={holidayModalState.open}
        onSave={handleDateSave}
        dates={holidayModalState.dates}
        onClose={() => setHolidayModalState(holdiayModalDefaultState)}
        onDateChange={(index, value) => {
          setHolidayModalState((prev) => {
            const modifiedDate = [...prev.dates];
            modifiedDate[index].date = new Date(value).toDateString();
            return { ...prev, dates: modifiedDate };
          });
        }}
        onAddDate={() => {
          setHolidayModalState((prev) => {
            return {
              ...prev,
              dates: [...prev.dates, { date: new Date().toDateString() }],
            };
          });
        }}
        onDeleteDate={(index) => {
          setHolidayModalState((prev) => {
            return {
              ...prev,
              dates: prev.dates.filter(
                (value, currIndex) => index !== currIndex
              ),
            };
          });
        }}
      />
    </>
  );
};

export default Cockpit;
