import { useEffect, useState } from 'react';
// import Cockpit from './components/Cockpit/Cockpit';
import axios from 'axios';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// import Header from './components/Header/Header';
// import SHC from './components/Cockpit/SHC';
import Header from './Header/Header';
import SHC from './Cockpit/SHC';
import Cockpit from './Cockpit/Cockpit';

function UniCloud() {
  const [IVRList, setIVRList] = useState(null);
  const [SHCRadiology, setSHCRAdiology] = useState({});

  useEffect(() => {
    const getIVRList = async () => {
      try {
        const IVRListResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/cockpit/getIVRDetails`
        );
        setIVRList(IVRListResponse.data);
      } catch (err) {
        console.log('IVR Config Fetch Error : ' + err);
      }
    };
    getIVRList();
  }, []);

  useEffect(() => {
    const getIVRList = async () => {
      try {
        const IVRListResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/cockpit/getSHCRadiology`
        );
        setSHCRAdiology(IVRListResponse.data);
      } catch (err) {
        console.log('IVR Config Fetch Error : ' + err);
      }
    };
    getIVRList();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          position: 'relative',
        }}
      >
        <Header />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // gap: '1rem',
            // padding: '1rem',
          }}
        >
          {/* {IVRList &&
            IVRList.map((res) => {
              return <Cockpit key={res.id} data={res} />;
            })} */}
          {Array.isArray(IVRList) && <Cockpit data={IVRList[0]} />}
          {/* <SHC data={{ ...SHCRadiology, IVRGlobalName: 'SHC_Cancer_Center' }} />
          <SHC data={{ ...SHCRadiology, IVRGlobalName: 'SHC_Radiology' }} /> */}
          <SHC data={Array.isArray(SHCRadiology) ? SHCRadiology[0] : {}} />
          <SHC data={Array.isArray(SHCRadiology) ? SHCRadiology[1] : {}} />
          {/* <SHC data={SHCRadiology[1]} /> */}
          {/* <SHC data={{ ...SHCRadiology, IVRGlobalName: 'SHC_Radiology' }} /> */}
          {/* {data &&
          data.map((res) => {
            return <Cockpit key={res.id} data={res} />;
          })} */}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default UniCloud;
