import { useState } from 'react';
import {
  IconButton,
  Box,
  TextField,
  Modal,
  Stack,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  borderRadius: '4px',
};

const HolidayModal = ({
  IVRName,
  open,
  onClose,
  onSave,
  dates,
  onAddDate,
  onDeleteDate,
  onDateChange,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            padding: '1rem',
            fontSize: '1.5rem',
            background: '#243B55',
            color: '#A9ADA5',
          }}
        >
          {'Details'}
        </Box>
        <Stack
          sx={{
            padding: '0.5rem 1.25rem',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          <Stack
            flexDirection={'row'}
            sx={{ fontSize: '14px', color: 'grey.500', gap: '0.25rem' }}
          >
            <Box>{`${IVRName} -`}</Box>
            <Box>{'Holiday Management'}</Box>
          </Stack>
          <Stack alignItems={'flex-end'} sx={{ margin: '0.25rem 0' }}>
            <Button onClick={onAddDate}>Add Date</Button>
          </Stack>
          <Box sx={{ height: '250px' }}>
            <Stack
              sx={{
                height: '100%',
                overflowY: 'auto',
                gap: '0.5rem',
                scrollbarWidth: 'thin',
              }}
            >
              {dates?.map((value, index) => {
                let d = value.split('/');
                let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
                console.log('Value:', value, dat);
                return (
                  <Stack
                    flexDirection={'row'}
                    gap={'0.5rem'}
                    alignItems={'center'}
                    key={index}
                  >
                    <DatePicker
                      sx={{ flex: '1' }}
                      value={dayjs(dat)}
                      onChange={(value) => onDateChange(index, value)}
                      format='DD/MM/YYYY'
                    />
                    <IconButton
                      sx={{
                        width: 'fit-content',
                        height: 'fit-content',
                      }}
                      onClick={() => onDeleteDate(index)}
                    >
                      <CloseIcon color='error' />
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-end'} p={2} gap={1}>
          <Button variant='outlined' onClick={onClose} size='medium'>
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              onSave();
              onClose();
            }}
            size='medium'
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default HolidayModal;
