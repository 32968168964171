import { useState } from 'react';
import { Box, TextField, Modal, Stack, Button } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  borderRadius: '4px',
};

const TextEditModal = ({ state, onClose, onSave }) => {
  const [inputValue, setInputValue] = useState(state.defaultValue);

  return (
    <Modal open={state.open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            padding: '1rem',
            fontSize: '1.5rem',
            background: '#243B55',
            color: '#A9ADA5',
          }}
        >
          Details
        </Box>
        <Stack
          sx={{
            padding: '0.5rem 1.25rem',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          <Stack
            flexDirection={'row'}
            sx={{ fontSize: '14px', color: 'grey.500', gap: '0.25rem' }}
          >
            <Box>{`${state.IVRGlobalName} -`}</Box>
            <Box>{state.title}</Box>
          </Stack>
          <Box mt={1} mb={1}>
            <TextField
              defaultValue={state.defaultValue}
              rows={10}
              variant='filled'
              label='Value'
              onChange={(e) => setInputValue(e.target.value)}
              fullWidth
              multiline
            ></TextField>
          </Box>
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-end'} p={2} gap={1}>
          <Button variant='outlined' onClick={onClose} size='medium'>
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              onSave(state.name, inputValue);
              onClose();
            }}
            size='medium'
            disabled={inputValue === '' || inputValue === state.defaultValue}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TextEditModal;
