import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Navigator from './components/navigator/Navigator';
import UniCloud from './components/unicloud/UniCloud';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';

// function App() {
//   const [IVRList, setIVRList] = useState(null);

//   useEffect(() => {
//     const getIVRList = async () => {
//       try {
//         const IVRListResponse = await axios.get(
//           `${process.env.REACT_APP_SERVER_URL}/cockpit/getIVRDetails`
//         );
//         setIVRList(IVRListResponse.data);
//       } catch (err) {
//         console.log('IVR Config Fetch Error : ' + err);
//       }
//     };
//     getIVRList();
//   }, []);

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           height: '100vh',
//         }}
//       >
//         <Header />
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             gap: '1rem',
//             padding: '1rem',
//           }}
//         >
//           {IVRList &&
//             IVRList.map((res) => {
//               return <Cockpit key={res.id} data={res} />;
//             })}
//         </Box>
//       </Box>
//     </LocalizationProvider>
//   );
// }

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Navigate to={'/navigator'} />} />
          <Route path='unicloud' element={<UniCloud />} />
          <Route path='navigator' element={<Navigator />} />
          <Route path='*' element={<div>Page Not Found</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
