import { useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  Paper,
} from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import TitleIcon from '@mui/icons-material/Title';
import axios from 'axios';

import TextEditModal from './TextSHCEditModal';
import {
  iconStyle,
  boxItemStyle,
  stackBoxWrapperStyle,
  iconWrapperStyle,
  titleStyle,
  gridItemStyle,
  editBtnStyle,
  editBtnWrapperStyle,
  textFieldStyle,
} from './Cockpit';
import DoubleArrow from '@mui/icons-material/DoubleArrow';

const modalDefaultState = {
  open: false,
  IVRGlobalName: '',
  title: '',
  defaultValue: '',
  name: '',
};

const SHC = ({ data }) => {
  const [resData, setResData] = useState({});
  const [modalState, setModalState] = useState({ ...modalDefaultState });
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    setResData(data);
  }, [data]);

  const handleModalState = (name, title, defaultValue) => {
    setModalState({
      open: true,
      IVRGlobalName: resData.IVRGlobalName,
      name,
      title,
      defaultValue,
    });
  };

  useEffect(() => {
    if (isUpdate) {
      const updateIVRConfig = async (newIVRData) => {
        console.log('UpdateIVRConfig:', newIVRData);
        console.log('ModalState:', modalState);
        try {
          const updateIVRRespone = await axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/cockpit/updateSHCRadiology`,
            // `${process.env.REACT_APP_SERVER_URL}/cockpit/getSHCRadiology`,
            newIVRData,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
          console.log('IVR Configuration Update Success : ' + updateIVRRespone);
        } catch (err) {
          console.log('IVR Configuration Update Failed : ' + err);
        }
      };
      updateIVRConfig(resData);
      setIsUpdate(false);
    }
  }, [isUpdate]);

  const onSave = (name, value) => {
    console.log(name, value);
    setResData((prev) => {
      return { ...prev, [name]: value };
    });
    setIsUpdate(true);
  };

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          // padding: '1rem'
          // borderBottom: '1px solid',
          // borderColor: '#243B55',
        }}
        elevate={2}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            padding: '2rem',
            justifyContent: 'space-between',
            borderRight: '1px solid',
            borderColor: 'grey.300',
            // marginRight: '1rem',
            minWidth: '200px',
            background: '#243B55',
            borderBottom: '1px solid',
            borderColor: '#A9ADA5',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              // color: '#232a50',
              // color: '#1d2e43',
              // color: 'white',
              color: '#A9ADA5',
              fontSize: '20px',
            }}
          >
            {resData?.IVRGlobalName ?? ''}
          </Typography>
          <IconButton>
            <LaunchIcon
              sx={{
                // color: '#304d70',
                color: '#A9ADA5',
              }}
            />
          </IconButton>
        </Stack>
        <Box
          sx={{
            flex: '5',
            padding: '1rem',
            borderBottom: '1px solid',
            borderColor: '#243B55',
          }}
        >
          <Box
            // container
            // flex={4}
            // flex={5}
            columnGap={2}
            rowGap={2}
            // marginLeft={2}
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <Box sx={gridItemStyle} key={'Step1'}>
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <DoubleArrowIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Step 1'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState('Step1', 'Step 1', resData.Step1)
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.Step1 ?? ''}</Box>
                </Button>
              </Paper>
            </Box>
            <Box sx={gridItemStyle} key={'Step2'}>
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <DoubleArrowIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Step 2'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState('Step2', 'Step 2', resData.Step2)
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.Step2 ?? ''}</Box>
                </Button>
              </Paper>
            </Box>
            <Box sx={gridItemStyle} key={'Step3'}>
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <DoubleArrowIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Step 3'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState('Step3', 'Step 3', resData.Step3)
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.Step3 ?? ''}</Box>
                </Button>
              </Paper>
            </Box>
            <Box sx={gridItemStyle} key={'Step4'}>
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <DoubleArrowIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Step 4'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState('Step4', 'Step 4', resData.Step4)
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.Step4 ?? ''}</Box>
                </Button>
              </Paper>
            </Box>
            <Box sx={gridItemStyle} key={'ConsiderIF'}>
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <TitleIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Consider IF'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'ConsiderIF',
                      'Consider IF',
                      resData.ConsiderIF
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.ConsiderIF ?? ''}</Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'primary-calls-in-queue'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    {/* <WatchLaterIcon sx={iconStyle} /> */}
                    <Box
                      sx={{
                        ...iconStyle,
                        fontWeight: 'bold',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      {'P'}
                    </Box>
                  </Box>
                  <Box sx={titleStyle}>{'Primary Area Calls In Queue'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'PrimaryCallsInQueue',
                      'Primary Calls In Queue',
                      resData.PrimaryCallsInQueue
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>
                    {resData.PrimaryCallsInQueue ?? ''}
                  </Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'secondary-calls-in-queue'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    {/* <TitleIcon sx={iconStyle} /> */}
                    <Box
                      sx={{
                        ...iconStyle,
                        fontWeight: 'bold',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      {'S'}
                    </Box>
                  </Box>
                  <Box sx={titleStyle}>{'Secondary Area Calls In Queue'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'SecondaryCallsInQueue',
                      'Secondary Calls In Queue',
                      resData.SecondaryCallsInQueue
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <EditIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>
                    {resData.SecondaryCallsInQueue ?? ''}
                  </Box>
                </Button>
              </Paper>
            </Box>
            <Box
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              sx={gridItemStyle}
              key={'opening-hours'}
            >
              <Paper elevation={2} sx={boxItemStyle}>
                <Stack sx={stackBoxWrapperStyle}>
                  <Box sx={iconWrapperStyle}>
                    <InfoIcon sx={iconStyle} />
                  </Box>
                  <Box sx={titleStyle}>{'Opening Hours'}</Box>
                </Stack>
                <Button
                  sx={stackBoxWrapperStyle}
                  onClick={() =>
                    handleModalState(
                      'OpeningHours',
                      'Opening Hours',
                      resData.OpeningHours
                    )
                  }
                >
                  <Box sx={editBtnWrapperStyle}>
                    <LaunchIcon sx={editBtnStyle} />
                  </Box>
                  <Box sx={textFieldStyle}>{resData.OpeningHours ?? ''}</Box>
                </Button>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Paper>
      <TextEditModal
        onSave={onSave}
        state={modalState}
        onClose={() => setModalState(modalDefaultState)}
      />
    </>
  );
};

export default SHC;
